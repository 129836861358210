import React, { PureComponent } from 'react'

const icons = {
  play: [
    {
      type: 'path',
      props: { d: 'M295.962,812.335l483.912-300.351L295.962,211.666V812.335z' },
    },
  ],
  playInCircle: [
    {
      type: 'path',
      props: {
        d: 'M512,1020.057c-68.583,0-135.119-13.436-197.761-39.93c-60.5-25.59-114.833-62.221-161.489-108.877S69.463,770.262,43.874,709.762C17.378,647.119,3.944,580.582,3.944,512c0-68.582,13.434-135.119,39.93-197.762c25.589-60.5,62.221-114.833,108.876-161.488c46.656-46.656,100.988-83.288,161.489-108.877C376.881,17.377,443.418,3.943,512,3.943c68.583,0,135.119,13.434,197.762,39.93c60.5,25.589,114.833,62.221,161.488,108.877c46.656,46.655,83.288,100.988,108.877,161.488c26.495,62.643,39.93,129.18,39.93,197.762c0,68.583-13.435,135.12-39.93,197.762c-25.589,60.5-62.221,114.832-108.877,161.488c-46.655,46.656-100.988,83.287-161.488,108.877C647.119,1006.621,580.583,1020.057,512,1020.057L512,1020.057z M511.999,51.071C257.843,51.071,51.071,257.843,51.071,512c0,254.158,206.771,460.929,460.928,460.929C766.157,972.93,972.93,766.158,972.93,512C972.93,257.843,766.157,51.071,511.999,51.071L511.999,51.071z',
      },
    },
    {
      type: 'path',
      props: {
        d: 'M512,1020.057c-68.583,0-135.119-13.435-197.761-39.93c-60.5-25.589-114.833-62.221-161.489-108.877c-46.656-46.655-83.287-100.988-108.876-161.488C17.378,647.119,3.944,580.583,3.944,512c0-68.583,13.434-135.119,39.93-197.761c25.589-60.5,62.221-114.833,108.876-161.488c46.656-46.656,100.988-83.288,161.489-108.877C376.881,17.378,443.417,3.944,512,3.944c68.583,0,135.119,13.434,197.762,39.93c60.5,25.589,114.833,62.221,161.488,108.877c46.656,46.655,83.288,100.988,108.877,161.488c26.495,62.642,39.93,129.179,39.93,197.761c0,68.583-13.435,135.119-39.93,197.762c-25.589,60.5-62.221,114.833-108.877,161.488c-46.655,46.656-100.988,83.288-161.488,108.877C647.119,1006.622,580.583,1020.057,512,1020.057L512,1020.057z M511.999,51.072c-254.156,0-460.928,206.772-460.928,460.929S257.843,972.93,511.999,972.93c254.158,0,460.931-206.771,460.931-460.929S766.157,51.072,511.999,51.072L511.999,51.072z',
      },
    },
    {
      type: 'path',
      props: { d: 'M366.222,773.415l421.201-261.429l-421.201-261.4V773.415z' },
    },
  ],
  previous: [
    {
      type: 'polygon',
      props: {
        points:
          '463.545,598.816 463.545,653.413 201.649,540.402 201.649,478.026 463.545,364.302 463.545,419.635 252.174,508.899',
      },
    },
    {
      type: 'polygon',
      props: {
        points:
          '779.873,598.816 779.873,653.413 517.921,540.402 517.921,478.026 779.873,364.302 779.873,419.635 568.467,508.899',
      },
    },
  ],
  next: [
    {
      type: 'polygon',
      props: {
        points:
          '517.995,414.762 517.995,360.165 779.874,473.172 779.874,535.57 517.995,649.259 517.995,593.944 729.363,504.677',
      },
    },
    {
      type: 'polygon',
      props: {
        points:
          '201.649,414.762 201.649,360.165 463.598,473.172 463.598,535.57 201.649,649.259 201.649,593.944 413.059,504.677',
      },
    },
  ],
  pause: [
    {
      type: 'rect',
      props: {
        x: '305.256',
        y: '255.374',
        width: '120.352',
        height: '513.252',
      },
    },
    {
      type: 'rect',
      props: {
        x: '598.393',
        y: '255.373',
        width: '120.352',
        height: '513.253',
      },
    },
  ],
  pauseInCircle: [
    {
      type: 'rect',
      props: {
        x: '327.529',
        y: '283.021',
        width: '107.385',
        height: '457.958',
      },
    },
    {
      type: 'rect',
      props: {
        x: '589.085',
        y: '283.021',
        width: '107.386',
        height: '457.958',
      },
    },
    {
      type: 'path',
      props: {
        d: 'M512,1020.057c-68.583,0-135.119-13.435-197.761-39.93c-60.5-25.589-114.833-62.221-161.489-108.877c-46.656-46.655-83.287-100.988-108.876-161.488C17.378,647.119,3.944,580.583,3.944,512c0-68.582,13.434-135.119,39.93-197.762c25.589-60.5,62.221-114.833,108.876-161.488c46.656-46.656,100.988-83.288,161.489-108.877C376.881,17.378,443.418,3.944,512,3.944c68.583,0,135.119,13.434,197.762,39.93c60.5,25.589,114.833,62.221,161.488,108.877c46.656,46.655,83.288,100.988,108.877,161.488c26.495,62.643,39.93,129.18,39.93,197.762c0,68.583-13.435,135.119-39.93,197.762c-25.589,60.5-62.221,114.833-108.877,161.488c-46.655,46.656-100.988,83.288-161.488,108.877C647.119,1006.622,580.583,1020.057,512,1020.057L512,1020.057z M511.999,51.072c-254.156,0-460.928,206.772-460.928,460.929S257.843,972.93,511.999,972.93c254.158,0,460.931-206.771,460.931-460.929S766.157,51.072,511.999,51.072L511.999,51.072z',
      },
    },
  ],
  addInCircle: [
    {
      type: 'path',
      props: {
        d: 'M512,1020.057c-68.583,0-135.119-13.435-197.761-39.93c-60.5-25.589-114.833-62.221-161.489-108.877c-46.656-46.655-83.287-100.988-108.876-161.488C17.378,647.119,3.944,580.583,3.944,512c0-68.582,13.434-135.119,39.93-197.762c25.589-60.5,62.221-114.833,108.876-161.488c46.656-46.656,100.988-83.288,161.489-108.877C376.881,17.378,443.418,3.944,512,3.944c68.583,0,135.119,13.434,197.762,39.93c60.5,25.589,114.833,62.221,161.488,108.877c46.656,46.655,83.288,100.988,108.877,161.488c26.495,62.643,39.93,129.18,39.93,197.762c0,68.583-13.435,135.119-39.93,197.762c-25.589,60.5-62.221,114.833-108.877,161.488c-46.655,46.656-100.988,83.288-161.488,108.877C647.119,1006.622,580.583,1020.057,512,1020.057L512,1020.057z M511.999,51.072c-254.156,0-460.928,206.772-460.928,460.929S257.843,972.93,511.999,972.93c254.158,0,460.931-206.771,460.931-460.929S766.157,51.072,511.999,51.072L511.999,51.072z',
      },
    },
    {
      type: 'polygon',
      props: {
        points:
          '740.979,458.308 565.691,458.308 565.691,283.021 458.307,283.021 458.307,458.308 283.021,458.308 283.021,565.693 458.307,565.693 458.307,740.979 565.691,740.979 565.691,565.693 740.979,565.693',
      },
    },
  ],
}

class PlayerIcon extends PureComponent {
  render() {
    const { type, height, width } = this.props
    return (
      <svg width={width} height={height} viewBox="0 0 1024 1024">
        {icons[type].map(({ type, props }, i) =>
          React.createElement(type, {
            ...props,
            className: 'svg-btn',
            key: 'shape-' + i,
          })
        )}
      </svg>
    )
  }
}

PlayerIcon.defaultProps = {
  width: '1em',
  height: '1em',
}

export default PlayerIcon
