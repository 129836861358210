import thumb01 from '../assets/images/gallery/thumbs/thumbnail01.jpg'
import thumb02 from '../assets/images/gallery/thumbs/thumbnail02.jpg'
import thumb03 from '../assets/images/gallery/thumbs/thumbnail03.jpg'
import thumb04 from '../assets/images/gallery/thumbs/thumbnail04.jpg'
import thumb05 from '../assets/images/gallery/thumbs/thumbnail05.jpg'
import thumb06 from '../assets/images/gallery/thumbs/thumbnail06.jpg'
import thumb07 from '../assets/images/gallery/thumbs/thumbnail07.jpg'
import thumb08 from '../assets/images/gallery/thumbs/thumbnail08.jpg'
import thumb09 from '../assets/images/gallery/thumbs/thumbnail09.jpg'
import thumb10 from '../assets/images/gallery/thumbs/thumbnail10.jpg'
import thumb11 from '../assets/images/gallery/thumbs/thumbnail11.jpg'
import thumb12 from '../assets/images/gallery/thumbs/thumbnail12.jpg'
import thumb13 from '../assets/images/gallery/thumbs/thumbnail13.jpg'
import thumb14 from '../assets/images/gallery/thumbs/thumbnail14.jpg'
import thumb15 from '../assets/images/gallery/thumbs/thumbnail15.jpg'
import thumb16 from '../assets/images/gallery/thumbs/thumbnail16.jpg'
import thumb17 from '../assets/images/gallery/thumbs/thumbnail17.jpg'
import thumb18 from '../assets/images/gallery/thumbs/thumbnail18.jpg'

import full01 from '../assets/images/gallery/full/gallery-image01.jpg'
import full02 from '../assets/images/gallery/full/gallery-image02.jpg'
import full03 from '../assets/images/gallery/full/gallery-image03.jpg'
import full04 from '../assets/images/gallery/full/gallery-image04.jpg'
import full05 from '../assets/images/gallery/full/gallery-image05.jpg'
import full06 from '../assets/images/gallery/full/gallery-image06.jpg'
import full07 from '../assets/images/gallery/full/gallery-image07.jpg'
import full08 from '../assets/images/gallery/full/gallery-image08.jpg'
import full09 from '../assets/images/gallery/full/gallery-image09.jpg'
import full10 from '../assets/images/gallery/full/gallery-image10.jpg'
import full11 from '../assets/images/gallery/full/gallery-image11.jpg'
import full12 from '../assets/images/gallery/full/gallery-image12.jpg'
import full13 from '../assets/images/gallery/full/gallery-image13.jpg'
import full14 from '../assets/images/gallery/full/gallery-image14.jpg'
import full15 from '../assets/images/gallery/full/gallery-image15.jpg'
import full16 from '../assets/images/gallery/full/gallery-image16.jpg'
import full17 from '../assets/images/gallery/full/gallery-image17.jpg'
import full18 from '../assets/images/gallery/full/gallery-image18.jpg'

const defaultState = [
  { thumb: thumb01, full: full01 },
  { thumb: thumb02, full: full02 },
  { thumb: thumb03, full: full03 },
  { thumb: thumb04, full: full04 },
  { thumb: thumb05, full: full05 },
  { thumb: thumb06, full: full06 },
  { thumb: thumb07, full: full07 },
  { thumb: thumb08, full: full08 },
  { thumb: thumb09, full: full09 },
  { thumb: thumb10, full: full10 },
  { thumb: thumb11, full: full11 },
  { thumb: thumb12, full: full12 },
  { thumb: thumb13, full: full13 },
  { thumb: thumb14, full: full14 },
  { thumb: thumb15, full: full15 },
  { thumb: thumb16, full: full16 },
  { thumb: thumb17, full: full17 },
  { thumb: thumb18, full: full18 },
]

const gallery = (state = defaultState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default gallery
